import { RouteConfigSingleView } from 'vue-router/types/router';
import Home from '@/views/Home.vue';
import GettingStarted from '@/views/getting-started/GettingStarted.vue';
import AccessPoints from '@/views/getting-started/AccessPoints.vue';
import DataDelivery from '@/views/getting-started/DataDelivery.vue';
import Activation from '@/views/getting-started/Activation.vue';
import ez2viewInstaller from '@/views/getting-started/ez2viewInstaller.vue';
import ez2updateInstaller from '@/views/getting-started/ez2updateInstaller.vue';
import DesignPrinciples from '@/views/design-principles/DesignPrinciples.vue';
import BuildDashboard from '@/views/design-principles/BuildDashboard.vue';
import PointInTime from '@/views/design-principles/PointInTime.vue';
import Hyperlinks from '@/views/design-principles/Hyperlinks.vue';
import ez2viewExplorer from '@/views/design-principles/ez2viewExplorer.vue';
import InBuiltAlerts from '@/views/design-principles/InBuiltAlerts.vue';
import CustomAlerts from '@/views/design-principles/CustomAlerts.vue';
import QuickAccessButtons from '@/views/design-principles/QuickAccessButtons.vue';
import SharingPreferences from '@/views/design-principles/SharingPreferences.vue';
import PrivateData from '@/views/design-principles/PrivateData.vue';
import ComplexConcepts from '@/views/complex-market-concepts/ComplexConcepts.vue';
import Bidding from '@/views/complex-market-concepts/Bidding.vue';
import Constraints from '@/views/complex-market-concepts/Constraints.vue';
import FCAS from '@/views/complex-market-concepts/FCAS.vue';
import UnitDispatch from '@/views/complex-market-concepts/UnitDispatch.vue';
import Forecasting from '@/views/complex-market-concepts/Forecasting.vue';
import Conformance from '@/views/complex-market-concepts/Conformance.vue';
import Regions from '@/views/complex-market-concepts/Regions.vue';
import WidgetGuides from '@/views/widget-guides/WidgetGuides.vue';
import ApplicationMessages from '@/views/widget-guides/v8.0/ApplicationMessages.vue';
//import BidDetails from '@/views/widget-guides/v8.0/BidDetails.vue';
import BidExplorer from '@/views/widget-guides/v9.4/BidExplorer.vue';
import ConstraintDashboard from '@/views/widget-guides/v9.5/ConstraintDashboard.vue';
import ConstraintDashboardPostIess from "@/views/widget-guides/v9.9/ConstraintDashboardPostIess.vue";
//import BidsandOffers from '@/views/widget-guides/v8.0/BidsandOffers.vue';
import BoundConstraints from '@/views/widget-guides/v8.0/BoundConstraints.vue';
import ConfiguredTrends from '@/views/widget-guides/v8.0/ConfiguredTrends.vue';
import ConstraintEquationDetails from '@/views/widget-guides/v8.0/ConstraintEquationDetails.vue';
import ConstraintEquations from '@/views/widget-guides/v8.0/ConstraintEquations.vue';
import ConstraintSetDetails from '@/views/widget-guides/v8.0/ConstraintSetDetails.vue';
import ConstraintSets from '@/views/widget-guides/v8.0/ConstraintSets.vue';
// import CurrentBids from '@/views/widget-guides/v8.0/CurrentBids.vue';
import DispatchConstraints from '@/views/widget-guides/v8.0/DispatchConstraints.vue';
//import ForecastConvergence from '@/views/widget-guides/v8.0/ForecastConvergence.vue';
import FuelTypeDetails from '@/views/widget-guides/v8.0/FuelTypeDetails.vue';
import GasData from '@/views/widget-guides/v8.0/GasData.vue';
import GenChange from '@/views/widget-guides/v8.0/GenChange.vue';
//import GeneratorOutages from '@/views/widget-guides/v9.5/GeneratorOutages.vue';
import InterconnectorDetails from '@/views/widget-guides/v8.0/InterconnectorDetails.vue';
import InterconnectorResidueTrend from '@/views/widget-guides/v8.0/InterconnectorResidueTrend.vue';
import LargeScaleIntermittentGenerationTrend from '@/views/widget-guides/v8.0/LargeScaleIntermittentGenerationTrend.vue';
import MarketNotices from '@/views/widget-guides/v8.0/MarketNotices.vue';
import MITCAERReport from '@/views/widget-guides/v8.0/MITCAERReport.vue';
import MITCAnalysis from '@/views/widget-guides/v8.0/MITCAnalysis.vue';
import MITCOutageReport from '@/views/widget-guides/v8.0/MITCOutageReport.vue';
// import NEMMap from '@/views/widget-guides/v8.0/NEMMap.vue';
import NEMPricesDeprecated from '@/views/widget-guides/v8.0/NEMPricesDeprecated.vue';
import NEMPrices from '@/views/widget-guides/v9.5/NEMPrices.vue';
import NEMSchematic from '@/views/widget-guides/v8.0/NEMSchematic.vue';
import NEMSummaryStats from '@/views/widget-guides/v8.0/NEMSummaryStats.vue';
//import Notifications from '@/views/widget-guides/v8.0/Notifications.vue';
// import PASA from '@/views/widget-guides/v8.0/PASA.vue';
import MTPASA from '@/views/widget-guides/v8.0/MTPASA.vue';
import PortfolioDetails from '@/views/widget-guides/v8.0/PortfolioDetails.vue';
// import PriceSetterData from '@/views/widget-guides/v8.0/PriceSetterData.vue';
import RegionalCommoditySummaryTrend from '@/views/widget-guides/v8.0/RegionalCommoditySummaryTrend.vue';
import RegionalConsumption from '@/views/widget-guides/v8.0/RegionalConsumption.vue';
//import RegionalOverview from '@/views/widget-guides/v8.0/RegionalOverview.vue';
import RegionalSchematics from '@/views/widget-guides/v8.0/RegionalSchematics.vue';
import RegionalSensitivities from '@/views/widget-guides/v8.0/RegionalSensitivities.vue';
import RegionalSummaryStats from '@/views/widget-guides/v8.0/RegionalSummaryStats.vue';
//import RegionalTradingPrices from '@/views/widget-guides/v8.0/RegionalTradingPrices.vue';
import RegionalTrends from '@/views/widget-guides/v8.0/RegionalTrends.vue';
import SAPriceRiskForecast from '@/views/widget-guides/v8.0/SAPriceRiskForecast.vue';
import SMSAlarms from '@/views/widget-guides/v8.0/SMSAlarms.vue';
import StationDetails from '@/views/widget-guides/v9.9/StationDetails.vue';
import StationStandingData from '@/views/widget-guides/v8.0/StationStandingData.vue';
//import SwimLanes from '@/views/widget-guides/v8.0/SwimLanes.vue';
// import TrendEditor from '@/views/widget-guides/v8.0/TrendEditor.vue';
import UnitDashboard from '@/views/widget-guides/v9.9/UnitDashboard.vue';
import UnitMetaData from '@/views/widget-guides/v8.0/UnitMetaData.vue';

import CongestionMapPrototype from '@/views/widget-guides/v9.9/CongestionMapPrototype.vue';


import TimelineOfDevelopment from '@/views/ongoing-development/TimelineOfDevelopment.vue';
import ContactUs from '@/views/contact-us/ContactUs.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import RedirectToReleaseNotes from '@/views/ongoing-development/RedirectToReleaseNotes.vue';
import DataSources from '@/views/design-principles/DataSources.vue';

export default [
  { path: '', name: 'Home', component: Home },
  {
    path: 'getting-started',
    name: 'Getting Started',
    component: GettingStarted,
    children: [
      { path: 'accesspoints', name: 'Access Points', component: AccessPoints },
      { path: 'datadelivery', name: 'Data Delivery', component: DataDelivery },
      { path: 'activation', name: 'Activation', component: Activation },
      { path: 'ez2viewinstaller', name: 'ez2view Installer', component: ez2viewInstaller },
      { path: 'ez2updateinstaller', name: 'ez2update Installer', component: ez2updateInstaller }
    ]
  },
  {
    path: 'design-principles',
    name: 'Design Principles',
    component: DesignPrinciples,
    children: [
      { path: 'builddashboard', name: 'Build Your Own Dashboard', component: BuildDashboard },
      { path: 'pointintime', name: 'Point in Time View', component: PointInTime },
      { path: 'hyperlinks', name: 'Context-sensitive Linkages', component: Hyperlinks },
      { path: 'ez2viewexplorer', name: 'ez2view Explorer', component: ez2viewExplorer },
      { path: 'inbuiltalerts', name: 'Alerting Colours and Icons', component: InBuiltAlerts },
      { path: 'customalerts', name: 'Customising Alerts', component: CustomAlerts },
      { path: 'quickaccessbuttons', name: 'Quick Access Buttons', component: QuickAccessButtons },
      { path: 'sharingpreferences', name: 'Sharing Preferences', component: SharingPreferences },
      { path: 'datasources', name: 'Data Sources', component: DataSources },
      { path: 'privatedata', name: 'Private Data', component: PrivateData, meta: { hide: true } }
    ]
  },
  {
    path: 'complex-market-concepts',
    name: 'Complex Market Concepts',
    component: ComplexConcepts,
    meta: { hideChildren: true },
    children: [
      { path: 'unitdispatch', name: 'Unit Dispatch', component: UnitDispatch, meta: { hide: true } },
      { path: 'bidding', name: 'Bidding & Price Setting', component: Bidding, meta: { hide: true } },
      { path: 'regions', name: 'Regions & Interconnectors', component: Regions, meta: { hide: true } },
      { path: 'fcas', name: 'FCAS and Co-optimisation', component: FCAS, meta: { hide: true } },
      { path: 'constraints', name: 'Constraints', component: Constraints, meta: { hide: true } },
      { path: 'forecasting', name: 'Forecasting', component: Forecasting, meta: { hide: true } },
      { path: 'conformance', name: 'Conformance', component: Conformance, meta: { hide: true } }
    ]
  },
  {
    path: 'widget-guides',
    name: 'Widget Guides',
    component: WidgetGuides,
    meta: { hideChildren: true },
    children: [
      //Application Messages Widget
      { path: ':version/application-messages', name: 'Application Messages', component: ApplicationMessages },
      //Bid Details Widget
      { path: '8.0/bid-details', component: () => import('@/views/widget-guides/v8.0/BidDetails.vue') },
      { path: '9.0/bid-details', component: () => import('@/views/widget-guides/v9.0/BidDetails.vue') },
      { path: ':version/bid-details', component: () => import('@/views/widget-guides/v9.0/BidDetails.vue') },
      //Bid Explorer Widget
      { path: ':version/bid-explorer', name: 'Bid Explorer', component: BidExplorer },
      //Bids & Offers Widget
      { path: '8.0/bids-and-offers', component: () => import('@/views/widget-guides/v8.0/BidsandOffers.vue') },
      { path: '9.0/bids-and-offers', component: () => import('@/views/widget-guides/v9.0/BidsandOffers.vue') },
      { path: '9.5/bids-and-offers', component: () => import('@/views/widget-guides/v9.5/BidsandOffers.vue') },
      { path: ':version/bids-and-offers', component: () => import('@/views/widget-guides/v9.8/BidsandOffers.vue') },
      //Bound Constraints Widget
      { path: ':version/bound-constraints', name: 'Bound Constraints', component: BoundConstraints },
      //Configured Trends Widget
      { path: ':version/configured-trends', name: 'Configured Trends', component: ConfiguredTrends },
      //Constraint Dashboard Widget
      { path: '9.4/constraint-dashboard', component: () => import('@/views/widget-guides/v9.4/ConstraintDashboard.vue') },
      { path: ':version/constraint-dashboard', name: 'Constraint Equation Dashboard', component: ConstraintDashboard },
      { path: ':version/constraint-dashboard-post-iess', name: 'Constraint Equation Dashboard', component: ConstraintDashboardPostIess },
      //Constraint Equation Details Widget
      { path: ':version/constraint-equation-details', name: 'Constraint Equation Details', component: ConstraintEquationDetails },
      //Constraint Equations Widget
      { path: ':version/constraint-equations', name: 'Constraint Equations', component: ConstraintEquations },
      //Constraint Set Details Widget
      { path: ':version/constraint-set-details', name: 'Constraint Set Details', component: ConstraintSetDetails },
      //Constraint Sets Widget
      { path: '9.9/constraint-sets', component: () => import('@/views/widget-guides/v9.9/ConstraintSets.vue') },
      { path: ':version/constraint-sets', name: 'Constraint Sets', component: ConstraintSets },
      //Current Bids Widget
      { path: '8.0/current-bids', component: () => import('@/views/widget-guides/v8.0/CurrentBids.vue') },
      { path: ':version/current-bids', component: () => import('@/views/widget-guides/v9.8/CurrentBids.vue') },
      //Dispatch Constraints Widget
      { path: ':version/dispatch-constraints', name: 'Disptach Constraints', component: DispatchConstraints },
      //Forecast Convergence Widget
      { path: '8.0/forecast-convergence', component: () => import('@/views/widget-guides/v8.0/ForecastConvergence.vue') },
      { path: '9.0/forecast-convergence', component: () => import('@/views/widget-guides/v9.0/ForecastConvergence.vue') },
      { path: '9.4/forecast-convergence', component: () => import('@/views/widget-guides/v9.4/ForecastConvergence.vue') },
      { path: '9.5/forecast-convergence', component: () => import('@/views/widget-guides/v9.5/ForecastConvergence.vue') },
      { path: '9.6/forecast-convergence', component: () => import('@/views/widget-guides/v9.5/ForecastConvergence.vue') },
      { path: '9.7/forecast-convergence', component: () => import('@/views/widget-guides/v9.5/ForecastConvergence.vue') },
      { path: ':version/forecast-convergence', component: () => import('@/views/widget-guides/v9.8/ForecastConvergence.vue') },
      //Fuel Type Details Widget
      { path: ':version/fuel-type-details', name: 'Fuel Type Details', component: FuelTypeDetails },
      //Gas Data Widget
      { path: ':version/gas-data', name: 'Gas Data', component: GasData },
      //Gen Change Widget
      { path: ':version/gen-change', name: 'Gen Change', component: GenChange },
      //Generator Outages Widget
      { path: '9.4/generator-outages', component: () => import('@/views/widget-guides/v9.4/GeneratorOutages.vue') },
      { path: '9.5/generator-outages', component: () => import('@/views/widget-guides/v9.4/GeneratorOutages.vue') },
      { path: '9.6/generator-outages', component: () => import('@/views/widget-guides/v9.4/GeneratorOutages.vue') },
      { path: '9.7/generator-outages', component: () => import('@/views/widget-guides/v9.4/GeneratorOutages.vue') },
      { path: ':version/generator-outages', component: () => import('@/views/widget-guides/v9.8/GeneratorOutages.vue') },
      //Interconnector Details Widget
      { path: ':version/interconnector-details', name: 'Interconnector Details', component: InterconnectorDetails },
      //Interconnector Residue Trend Widget
      { path: ':version/interconnector-residue-trend', name: 'Interconnector Residue Trend', component: InterconnectorResidueTrend },
      { path: ':version/large-scale-intermittent-generation-trend', name: 'Large Scale Intermittent Generation Trend', component: LargeScaleIntermittentGenerationTrend },
      //Market Notices Widget
      { path: ':v8.0/aemo-market-notices', name: 'Market Notices (v8)', component: MarketNotices },
      { path: ':version/aemo-market-notices', name: 'Market Notices', component: MarketNotices },
      //MITC AER Report Widget
      { path: ':version/mitc-aer-report', name: 'MITC AER Report', component: MITCAERReport },
      //MITC Analysis Widget
      { path: ':9.7/mitc-analysis', component: () => import('@/views/widget-guides/v9.7/MITCAnalysis.vue') },
      { path: ':version/mitc-analysis', name: 'MITC Analysis', component: MITCAnalysis },
      //MITC Outage Report Widget
      { path: ':version/mitc-outage-report', name: 'MITC Outage Report', component: MITCOutageReport },
      //NEM Map Widget
      { path: '8.0/nem-map', component: () => import('@/views/widget-guides/v8.0/NEMMap.vue') },
      { path: '9.0/nem-map', component: () => import('@/views/widget-guides/v8.0/NEMMap.vue') },
      { path: '9.1/nem-map', component: () => import('@/views/widget-guides/v8.0/NEMMap.vue') },
      { path: '9.2/nem-map', component: () => import('@/views/widget-guides/v8.0/NEMMap.vue') },
      { path: '9.3/nem-map', component: () => import('@/views/widget-guides/v9.3/NEMMap.vue') },
      { path: '9.4/nem-map', component: () => import('@/views/widget-guides/v9.3/NEMMap.vue') },
      { path: ':version/nem-map', component: () => import('@/views/widget-guides/v9.5/NEMMap.vue') },
      //NEM Prices Widget
      { path: ':version/nem-prices-deprecated', name: 'NEM Prices (deprecated)', component: NEMPricesDeprecated },
      //NEM Prices Beta Widget
      { path: ':version/nem-prices-beta', name: 'NEM Prices Beta', component: NEMPrices },
      { path: '9.4/nem-prices', component: () => import('@/views/widget-guides/v9.4/NEMPrices.vue') },
      { path: '9.5/nem-prices', component: () => import('@/views/widget-guides/v9.5/NEMPrices.vue') },
      { path: ':version/nem-prices', component: () => import('@/views/widget-guides/v9.8/NEMPrices.vue') },
      //NEM Schematic Widget
      { path: ':version/nem-schematic', name: 'NEM Schematic', component: NEMSchematic },
      //NEM Summary Stats Widget
      { path: ':version/nem-summary-stats', name: 'NEM Summary Stats', component: NEMSummaryStats },
      //Notifications Widget
      { path: '8.0/notifications', component: () => import('@/views/widget-guides/v8.0/Notifications.vue') },
      { path: '9.0/notifications', component: () => import('@/views/widget-guides/v9.0/Notifications.vue') },
      { path: '9.5/notifications', component: () => import('@/views/widget-guides/v9.3/Notifications.vue') },
      { path: '9.6/notifications', component: () => import('@/views/widget-guides/v9.3/Notifications.vue') },
      { path: '9.7/notifications', component: () => import('@/views/widget-guides/v9.3/Notifications.vue') },
      { path: ':version/notifications', component: () => import('@/views/widget-guides/v9.8/Notifications.vue') },
      //PASA Widget
      { path: '8.0/pasa', component: () => import('@/views/widget-guides/v8.0/PASA.vue') },
      { path: '9.8/pasa', component: () => import('@/views/widget-guides/v8.0/PASA.vue') },
      { path: ':version/pasa', component: () => import('@/views/widget-guides/v9.9/PASA.vue') },
      //MT PASA Widget
      { path: ':version/mt-pasa', name: 'MT PASA DUID Availability', component: MTPASA },
      //Portfolio Details Widget
      { path: ':version/portfolio-details', name: 'PortfolioDetails', component: PortfolioDetails },
      //Price Setter Data Widget
      { path: '8.0/price-setter-data', component: () => import('@/views/widget-guides/v8.0/PriceSetterData.vue') },
      { path: ':version/price-setter-data', component: () => import('@/views/widget-guides/v9.8/PriceSetterData.vue') },
      //Regional Commodity Summary Trend Widget
      { path: ':version/regional-commodity-summary-trend', name: 'Regional Commodity Summary Trend', component: RegionalCommoditySummaryTrend },
      //Regional Consumption Widget
      { path: ':version/regional-consumption', name: 'Regional Consumption', component: RegionalConsumption },
      //Regional Overview Widget
      { path: '8.0/regional-overview', component: () => import('@/views/widget-guides/v8.0/RegionalOverview.vue') },
      { path: '9.0/regional-overview', component: () => import('@/views/widget-guides/v9.0/RegionalOverview.vue') },
      { path: ':version/regional-overview', component: () => import('@/views/widget-guides/v9.0/RegionalOverview.vue') },
      //Regional Schematics Widget
      { path: ':version/regional-schematics', name: 'Regional Schematics', component: RegionalSchematics },
      //Regional Sensitivities Widget
      { path: ':version/regional-sensitivities', name: 'Regional Sensitivities', component: RegionalSensitivities },
      //Regional Summary Stats Widget
      { path: ':version/regional-summary-stats', name: 'Regional Summary Stats', component: RegionalSummaryStats },
      //Regional Trading Prices Widget
      { path: '8.0/regional-trading-prices', component: () => import('@/views/widget-guides/v8.0/RegionalTradingPrices.vue') },
      { path: '9.0/regional-trading-prices', component: () => import('@/views/widget-guides/v9.0/RegionalTradingPrices.vue') },
      { path: '9.3/regional-trading-prices', component: () => import('@/views/widget-guides/v9.3/RegionalTradingPrices.vue') },
      { path: ':version/regional-trading-prices', component: () => import('@/views/widget-guides/v9.8/RegionalTradingPrices.vue') },
      //Regional Trends Widget
      { path: ':version/regional-trends', name: 'Regional Trends', component: RegionalTrends },
      //SA Price Risk Forecast Widget
      { path: ':version/sa-price-risk-forecast', name: 'SA Price Risk Forecast', component: SAPriceRiskForecast },
      //SMS Alarms Widget
      { path: '9.8/sms-alarms', component: () => import('@/views/widget-guides/v9.8/SMSAlarms.vue') },
      { path: ':version/sms-alarms', name: 'SMS Alarms', component: SMSAlarms }, // v8.0  to v9.7
      //Station Details Widget
      { path: '8.0/station-dashboard', name: 'Station Details (dashboard)', component: StationDetails },
      { path: '9.9/station-dashboard', component: () => import('@/views/widget-guides/v9.9/StationDetails.vue') },
      { path: ':version/station-detail', name: 'Station Details (dashboard)', component: StationDetails },
      { path: ':version/station-dashboard', name: 'Station Details', component: StationDetails },
      //Station Standing Data Widget
      { path: ':version/station-standing-data', name: 'Station Standing Data', component: StationStandingData },
      //Swim Lanes Widget
      { path: '8.0/swim-lanes', component: () => import('@/views/widget-guides/v8.0/SwimLanes.vue') },
      { path: '9.0/swim-lanes', component: () => import('@/views/widget-guides/v9.0/SwimLanes.vue') },
      { path: '9.3/swim-lanes', component: () => import('@/views/widget-guides/v9.3/SwimLanes.vue') },
      { path: ':version/swim-lanes', component: () => import('@/views/widget-guides/v9.8/SwimLanes.vue') },
      //Trend Editor Widget
      { path: '8.0/trend-editor', component: () => import('@/views/widget-guides/v8.0/TrendEditor.vue') },
      { path: ':version/trend-editor', component: () => import('@/views/widget-guides/v9.6/TrendEditor.vue') },
      //Unit Dashboard Widget
      { path: '9.0/unit-dashboard', component: () => import('@/views/widget-guides/v9.0/UnitDashboard.vue') },
      { path: '9.2/unit-dashboard', component: () => import('@/views/widget-guides/v9.0/UnitDashboard.vue') },
      { path: '9.3/unit-dashboard', component: () => import('@/views/widget-guides/v9.0/UnitDashboard.vue') },
      { path: '9.4/unit-dashboard', component: () => import('@/views/widget-guides/v9.0/UnitDashboard.vue') },
      { path: '9.5/unit-dashboard', component: () => import('@/views/widget-guides/v9.5/UnitDashboard.vue') },
      { path: '9.6/unit-dashboard', component: () => import('@/views/widget-guides/v9.5/UnitDashboard.vue') },
      { path: '9.9/unit-dashboard', component: () => import('@/views/widget-guides/v9.9/UnitDashboard.vue') },
      { path: ':version/unit-dashboard', name: 'Unit dashboard', component: UnitDashboard },

      //Unit Meta Data Widget
      { path: ':version/unit-meta-data', name: 'Unit Meta Data', component: UnitMetaData },

      // Prototypes
      { path: '9.9/congestionmapprototype', name: 'Congestion Map Prototype', component: CongestionMapPrototype }
    ]
  },
 {
    path: 'timeline',
    name: 'Timeline of Development',
    component: TimelineOfDevelopment,
    children: []
  },
  {
    path: 'contact-us',
    name: 'Contact Us',
    component: ContactUs,
    children: []
  },
  {
    path: 'redirect-release-notes',
    name: 'Release Notes',
    component: RedirectToReleaseNotes
  },
  { path: '*', component: PageNotFound, meta: { hide: true } }
] as Array<RouteConfigSingleView>;
