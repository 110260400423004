<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mb-2 mx-auto">
        <router-link to="/getting-started" class="block">
          <p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Getting Started</p>
        </router-link>
      </div>
      <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
        <div class="-mt-px w-0 flex-1 flex">
          <router-link
            to="/getting-started/activation"
            class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            <!-- Heroicon name: arrow-narrow-left -->
            <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
            <p>Previous: <b> Activation</b></p>
          </router-link>
        </div>
        <div class="hidden md:-mt-px md:flex"></div>
        <div class="-mt-px w-0 flex-1 flex justify-end"></div>
      </nav>
      <div class="text-lg mx-auto mb-6 pb-2 border-b-1">
        <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">ez2view Installer</h1>
        <p class="text-xl text-gray-500 leading-7 font-semibold">
          We have followed a rapid agile software development methodology for more than a decade. As part of our development process, we frequently release new versions of the software which include
          the addition of new widgets and other performance enhancements.
        </p>
      </div>
      <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
        <p>
          We are currently working to develop ez2view to incorporating changes to the MMS schema related to IESS. As such we currently offer two release types for our clients to download - a 'IESS
          Transition Release' and a 'Latest and Greatest'. The table below outlines the differences between these two, along with a link to download each.
        </p>
        <br />
      </div>
      <div class="bg-white">
        <div class="max-w-screen-xl mx-auto bg-white py-16 sm:px-6 lg:px-8">
          <!-- xs to lg -->
          <div class="max-w-2xl mx-auto lg:hidden">Please use a desktop web browser to view the table and access install files.</div>

          <!-- lg+ -->
          <div class="hidden lg:block">
            <table class="w-full h-px table-fixed">
              <caption class="sr-only">
                Release Comparison
              </caption>
              <thead>
                <tr>
                  <th class="w-1/3 pb-4 px-6 text-left align-top text-lg pb-2 leading-7 font-bold text-gray-500" scope="col">
                    <span class="sr-only">Release Type</span>
                    <span>Release Type</span>
                  </th>
                  <th class="w-1/3 pb-6 px-6 text-3xl leading-7 font-bold text-gray-600 text-center" scope="col">IESS Transition Release</th>
                  <th class="w-1/3 pb-6 px-6 text-3xl leading-7 font-bold text-gray-400 text-center" scope="col">Latest and Greatest</th>
                </tr>
              </thead>
              <tbody class="border-t border-gray-200 divide-gray-200">
                <tr>
                  <th class="py-8 px-6 text-left align-top text-lg pb-4 leading-5 font-bold text-gray-500" scope="row">
                    <p class="text-lg pb-2 leading-5 font-bold">Latest Version</p>
                    <p class="text-sm leading-5 font-normal">The latest version available to download for each of these release types.</p>
                  </th>
                  <td>
                    <p class="text-center text-base leading-6 font-semibold text-gray-500">{{ stable.date }}</p>
                    <p class="text-center text-base leading-6 text-gray-500">{{ stable.versionno }}</p>
                  </td>
                  <td>
                    <p class="text-center text-base leading-6 font-semibold text-gray-500">{{ latest.date }}</p>
                    <p class="text-center text-base leading-6 text-gray-500">{{ latest.versionno }}</p>
                  </td>
                </tr>
                <tr>
                  <th class="py-8 px-6 text-left align-top text-lg pb-4 leading-5 font-bold text-gray-500" scope="row">
                    <br />
                    <p class="text-sm leading-5 font-bold"><i>Minimum Supported Version</i></p>
                    <p class="text-sm leading-5 font-normal">Please note the current minimum supported version is v9.9.3.351</p>
                  </th>
                  <td>
                    <p class="text-center m-4 mb-12 text-sm leading-5 text-gray-500">
                      This version is only compatible with <mark><b>MMS v5.3</b></mark
                      >.
                    </p>
                  </td>
                  <td>
                    <p class="text-center m-4 mb-12 text-sm leading-5 text-gray-600">
                      This version is compatible with <mark><b>MMS v5.3</b></mark> and <mark><b>MMS v5.4</b></mark>.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="align-top">
                    <p class="text-center m-4 mb-8 text-base leading-5 text-gray-500">
                      This version is compatible with MMS v5.3 and hence changes related to IESS. It is a temporary release while our software development continues. Please read the
                      <a href="https://timeline.ez2viewaustralia.info/2024/05/iess-v9-9-3-release-notes/" class="text-blue-600 hover:underline" target="blank">full release note</a> for a summary of
                      what's new and known limitations within this release.
                    </p>
                  </td>
                  <td class="align-top">
                    <p class="text-center m-4 mb-8 text-base leading-5 text-gray-500">
                      {{ latest.description }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button
                      type="button"
                      class="w-full bg-gray-600 border border-gray-300 rounded-md p-2 text-xs leading-5 font-semibold text-white hover:bg-gray-500 focus:outline-none focus:ring-gray transition duration-150 ease-in-out"
                    >
                      <a :href="stable.downloadLink"> Download Stable Release (64-bit) </a>
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="w-full bg-gray-400 border border-gray-300 rounded-md p-2 text-xs leading-5 font-semibold text-white hover:bg-gray-500 focus:outline-none focus:ring-gray transition duration-150 ease-in-out"
                    >
                      <a :href="latest.downloadLink">Download Latest and Greatest (64-bit)</a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <p class="text-center">
                      <a :href="stable.installGuideUrl" class="text-grblue font-bold inline hover:underline" target="_blank">
                        {{ stable.installGuideText }}
                      </a>
                    </p>
                  </td>
                  <td>
                    <p class="text-center">
                      <a :href="latest.installGuideUrl" class="text-grblue font-bold inline hover:underline" target="_blank">
                        {{ latest.installGuideText }}
                      </a>
                    </p>
                  </td>
                  <td>
                    <p class="text-center"></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <hr class="m-10" />
                  </td>
                  <td>
                    <hr class="m-10" />
                  </td>
                  <td>
                    <hr class="m-10" />
                  </td>
                </tr>

                <tr>
                  <th class="bg-gray-50 py-3 pl-6 text-sm leading-5 font-medium text-gray-900 text-left" colspan="5" scope="colgroup">Minimum Requirements</th>
                </tr>

                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">Operating System</th>
                  <td>Windows 10, Windows 11, Server 2016 and above</td>
                </tr>
                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">Processor</th>
                  <td>1-gigahertz (GHz) processor or faster. (Multi-core CPUs perform significantly better)</td>
                </tr>
                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">Disk Space</th>
                  <td>1GB free disk space</td>
                </tr>
                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">Graphics</th>
                  <td>DirectX 9 (DirectX 11 preferred) Video Card (GPU) with at least 256MB of Video RAM for hardware acceleration.</td>
                </tr>
                <tr>
                  <br />
                </tr>

                <tr>
                  <th class="bg-gray-50 py-3 pl-6 text-sm leading-5 font-medium text-gray-900 text-left" colspan="5" scope="colgroup">Functionality</th>
                </tr>

                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">
                    <p class="text-lg pb-2 leading-5 font-bold">Latest Functionality</p>
                    <p class="text-sm leading-5 font-normal">
                      We build new versions of ez2view almost every week, adding new widgets, improving functionality and making other performance enhancements.
                    </p>
                  </th>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-gray-400 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Not Included</span>
                  </td>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                </tr>

                <tr>
                  <th class="bg-gray-50 py-3 pl-6 text-sm leading-5 font-medium text-gray-900 text-left" colspan="5" scope="colgroup">Testing</th>
                </tr>

                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">
                    <p class="text-lg pb-2 leading-5 font-bold">Automated Testing</p>
                    <p class="text-sm leading-5 font-normal">
                      Every time changes to the software are checked in, our automated build process runs, including automated testing. Installers are only uploaded if all automated tests pass.
                    </p>
                  </th>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                </tr>

                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">
                    <p class="text-lg pb-2 leading-5 font-bold">Basic Manual Testing</p>
                    <p class="text-sm leading-5 font-normal">When “Bleeding Edge” versions have passed some limited manual testing, we manually list them so you can access.</p>
                  </th>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                </tr>

                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">
                    <p class="text-lg pb-2 leading-5 font-bold">Comprehensive Manual Testing</p>
                    <p class="text-sm leading-5 font-normal">We only run through an extensive manual batch of tests on the occasions when we seek to make a full stable release of the software.</p>
                  </th>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-gray-400 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Not Included</span>
                  </td>
                </tr>

                <tr>
                  <th class="bg-gray-50 py-3 pl-6 text-sm leading-5 font-medium text-gray-900 text-left" colspan="5" scope="colgroup">Documentation</th>
                </tr>

                <tr>
                  <th class="py-5 px-6 text-gray-500 text-left" scope="row">
                    <p class="text-lg pb-2 leading-5 font-bold">Up-to-date Online Guides</p>
                    <p class="text-sm leading-5 font-normal">When we make stable releases, we ensure there is extensive up-to-date online documentation for each widget.</p>
                  </th>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-green-500 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Included</span>
                  </td>
                  <td class="py-5 px-6">
                    <!-- Heroicon name: check -->
                    <svg class="h-10 w-10 text-gray-400 block mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                    </svg>
                    <span class="sr-only">Not Included</span>
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
        <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Licence Agreement</h3>
        <p>
          Please review the <a href="http://downloads.global-roam.com/licence/Global-Roam_SoftwareLicenceAgreement.pdf" class="text-blue-600 hover:underline" target="_blank">licence agreement</a>,
          which will apply to your full licence of ez2view.
        </p>
      </div>
      <br />
    </div>
  </div>
</template>

<script lang="js">
export default {
  title: 'ez2view Installer',
  data() {
    return {
      stable: {
        versionno: 'v9.9.3.351',
        date: '2024-05-27',
        downloadLink: 'https://installers.global-roam.com/ez2viewaustralia/Install_ez2viewAustralia_9.9.3-build.351_x64_net7.0-windows.msi',
        installGuideUrl: 'http://downloads.global-roam.com/ez2view/ez2viewAustraila_Installation_Instructions_v9.9.4.pdf',
        installGuideText: 'v9.9 Install Guide'
      },
      latest: {
        versionno: 'loading...',
        date: '',
        description: '',
        downloadLink: '',
      }
    };
  },
  async created() {
    const response = await window.fetch('https://grinstallers.blob.core.windows.net/ez2viewaustralia/latest.txt');

    if (response.status === 200) {
      const text = await response.text();
      const lines = text.split(/\r?\n/);
      this.latest = {
        versionno: "v"+lines[1],
        date: lines[2].substring(0, 10),
        downloadLink: lines[3],
        description: 'Published regularly. Our development team will link the latest bleeding edge version here as soon as the automated build and tests pass.  Generally has had some level of internal usage on the latest version of Windows.',
        installGuideUrl: 'http://downloads.global-roam.com/ez2view/ez2viewAustralia_Installation_Instructions_v9.10.pdf',
        installGuideText: 'v9.10 Install Guide'
      }
    } else {
      this.version = 'unavailable';
    }
  }
};
</script>
