<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Timeline of Development</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
            Our development team continually works to improve ez2view by adding new features and widgets that increase and expand the software's functionality and scope. 
            <br/>
            <br/>
            We periodically publish release notes, examples of use and other announcements about ez2view on a separate blog. You can access and subscribe to these updates by following the link below: 
            <br/>
            <br/>
            <a href="https://timeline.ez2viewaustralia.info" class="text-blue-600 hover:underline"><h2 class="text-3xl text-center font-extrabold sm:text-3xl">timeline.ez2viewaustralia.info</h2></a>
        </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h3 class="mt-24 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Our Agile approach to developing ez2view</h3>
            <p>The ez2view software was first released in 2003 … with <b>investment in the software <i>escalated</i> since late 2010 with <i>intensive, iterative development</i></b>.  We follow this <a href="http://home.global-roam.com/about/the-agile-way-in-which-we-work/" class="text-blue-600 hover:underline">Agile Development</a> (the specifics of the method have varied over time, but the underlying purpose has been the same).  </p>
            <br/>
            <p>Building ez2view with an Agile approach means that:</p>
            <ul class="gr-list-disc">
                <li>You get access to enhancements to the software <i>sooner</i>;</li>
                <li>You can <i>actively participate</i> in the Agile development process (if you choose to do so);</li>
                <li>You gain the benefit of <i>all of</i> the suggestions we implement, including those suggested by the growing number of other clients who are using the software.</li>
            </ul>
        </div>
    </div>
    </div>
</template>


<script>
export default {
    title: 'Timeline of Development'
};
</script>